<template>
  <div :class="$style.home">
    <h1 :class="$style.title">
      Choose the product:
    </h1>

    <div :class="$style.products">
      <router-link :to="{ name: 'Forms_Home' }">
        <div :class="[$style.product, $style['product--forms']]">
          <div :class="$style.image">
            <img
              :src="formsIcon"
              alt="Forms"
              :class="$style.icon"
            >
          </div>
          <div :class="$style.text">
            Forms
          </div>
        </div>
      </router-link>

      <router-link :to="{ name: 'DocumentsProcessesList'}">
        <div :class="[$style.product, $style['product--documents']]">
          <div :class="$style.image">
            <img
              :src="documentsIcon"
              alt="Documents"
              :class="$style.icon"
            >
          </div>
          <div :class="$style.text">
            Documents
          </div>
        </div>
      </router-link>

      <router-link :to="{ name: 'Actions_Intro' }">
        <div :class="[$style.product, $style['product--actions']]">
          <div :class="$style.image">
            <img
              :src="actionsIcon"
              alt="Actions"
              :class="$style.icon"
            >
          </div>
          <div :class="$style.text">
            Actions
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import formsIcon from '@/assets/forms-icon.svg';
import documentsIcon from '@/assets/documents-icon.svg';
import actionsIcon from '@/assets/actions-icon.svg';

export default {
  data() {
    return {
      formsIcon,
      documentsIcon,
      actionsIcon,
    };
  },
};
</script>

<style lang="scss" module>
.home {
  display: flex;
  flex-flow: column;
  padding-top: 100px;

  .title {
    margin-bottom: 40px;
    font-size: 30px;
    text-align: center;
  }
}

.products {
  display: flex;
  justify-content: center;
  gap: 60px;

  .product {
    .image {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 15px;
      border-radius: 10px;
      width: 120px;
      height: 120px;
      transition-duration: var(--transition-duration);
    }

    .text {
      font-size: 16px;
      text-align: center;
      text-transform: uppercase;
    }

    &:hover {
      .image {
        box-shadow: 0 17px 50px 0 rgba(0, 0, 0, 0.2);
      }
    }

    &--forms {
      .image {
        background-color: var(--color-forms);

        .icon {
          width: 80px;
          height: 80px;
        }
      }

      .text {
        color: var(--color-forms);
      }
    }

    &--documents {
      .image {
        background-color: var(--color-documents);

        .icon {
          width: 100px;
          height: 100px;
        }
      }

      .text {
        color: var(--color-documents);
      }
    }

    &--actions {
      .image {
        background-color: var(--color-actions);

        .icon {
          width: 100px;
          height: 100px;
        }
      }

      .text {
        color: var(--color-actions);
      }
    }
  }

  @media (max-width: 590px) {
    flex-flow: column;
    align-items: center;
  }
}
</style>
